/* main content */
.modal-open {
	overflow-y: visible;
	.modal {
		overflow: auto;
	}
}

.fancybox-enabled {
	overflow: visible;
	body {
		overflow-x: hidden;
	}
}

/* main nav */
.main__nav {
	position: fixed;
	bottom: 50%;
	left: 50%;
	font-size: 0;
	transform: rotate(-90deg);
	line-height: 1;
	z-index: 1;
	opacity: 0;
	margin-left: -1050px;
	ul {
		@include ul-default;
		li {
			position: relative;
			margin: 0 25px;
			@include inline-block;
			&:after {
				content: '';
				width: 8px;
				height: 8px;
				background-color: #fff;
				position: absolute;
				top: 15px;
				right: -30px;
				border-radius: 100%;
			}
			&:last-child:after {
				display: none;
			}
			a {
				display: block;
				font-family: $medium;
				font-size: 14px;
				color: #05b1e2;
				text-transform: uppercase;
				text-decoration: none;
				padding: 14px;
				&:hover {
					background-color: #000;
					color: #fff;
				}
			}
			&.active a {
				background-color: #000;
				color: #fff;
			}
		}
	}
	@media screen and (max-width: 1440px) {
		bottom: 55%;
		margin-left: -975px;
		ul li {
			margin: 0 15px;
			a {
				font-size: 12px;
			}
			&:after {
				right: -20px;
			}
		}
	}
	@media screen and (max-width: 1380px) {
		bottom: 50%;
		margin-left: -925px;
		ul li {
			margin: 0 10px;
			&:after {
				right: -15px;
			}
		}
	}
	@media screen and (max-width: 1280px) {
		display: none!important;
		opacity: 0!important;
		bottom: 0;
		left: 0;
		margin: 0;
		transform: rotate(0);
		visibility: hidden;
		z-index: -990;
	}
}
/* main nav */

/* main slider */
.slider {
	width: 1140px;
	margin: 0 auto!important;
	position: relative;
	&:after {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 55px;
		box-shadow: 0 0 30px rgba(0,0,0,0.5);
		z-index: -1;
	}
	.slick-slide {
		overflow: hidden;
		position: relative;
		outline: none;
		padding-bottom: 55px;
		&.slick-active {
			img {
				transform: scale(1);
			}
		}
		img {
			display: block;
			width: 100%;
			height: auto;
			filter: grayscale(100%);
			transition: all 1s cubic-bezier(0.7, 0, 0.3, 1);
      		transform: scale(1.2);
		}
		.slider__title {
			display: block;
			font-family: $medium;
			font-size: 40px;
			color: #fff;
			text-align: center;
			position: absolute;
			left: 0;
			right: 0;
			top: 50%;
			padding: 0px 75px 35px 75px;
			line-height: 1;
			transform: translate(0, -50%);
			&:after {
				content: '';
				width: 300px;
				height: 3px;
				background-color: #fff;
				position: absolute;
				left: 0;
				right: 0;
				bottom: 0;
				margin: 0 auto;
			}
		}
		.slider__number {
			display: block;
			font-family: $bold;
			font-size: 80px;
			color: #fff;
			position: absolute;
			right: 75px;
			bottom: 0px;
			padding-left: 97px;
			&:after {
				content: '';
				width: 70px;
				height: 3px;
				background-color: #fff;
				position: absolute;
				top: 50%;
				left: 0;
				transform: translate(0 -50%);
			}
			span {
				color: #05b1e2;
			}
		}
	}
	.slick-arrow {
		width: 45px;
		height: 35px;
		background-image: url(../img/left-arrow.svg);
		background-position: center center;
		background-size: contain;
		background-repeat: no-repeat;
		line-height: 35px;
		top: auto;
		bottom: 200px;
		transition: 300ms;
		z-index: 1;
		&:before {
			display: none;
		}
		&.slick-prev {
			transform: rotate(0deg);
			left: 30px;
		}
		&.slick-next {
			transform: rotate(-180deg);
			right: 30px;
		}
		&:hover {
			opacity: 0.7;
		}
	}
	.slick-dots {
		width: auto;
		bottom: auto;
		top: 50%;
		left: -40px;
		transform: translate(0, -50%);
		li {
			display: table;
			margin: 4px 0px;
			button {
				width: 8px;
				height: 8px;
				background-color: #ffffff;
				border-radius: 100%;
				padding: 0;
				transition: 300ms;
				&:before {
					display: none;
				}
				&:after {
					content: '';
					width: 16px;
					height: 16px;
					border: 4px solid transparent;
					position: absolute;
					border-radius: 100%;
					left: -4px;
					top: -4px;
					margin: 0 auto;
					transition: 300ms;
				}
				&:hover {
					background-color: transparent;
					&:after {
						border: 4px solid #fff;
					}
				}
			}
			&.slick-active {
				button {
					background-color: transparent;
					&:after {
						border: 4px solid #fff;
					}
				}
			}
		}
	}
	@media screen and (max-width: 1440px) {
		.slick-dots {
			left: -20px;
		}
	}
	@media screen and (max-width: 1280px) {
		width: 100%;
		.slick-dots {
			left: 15px;
		}
	}
	@media screen and (max-width: 991px) {
		&:after {
			bottom: 0;
		}
		.slick-slide {
			padding-bottom: 0px;
			.slider__title {
				font-size: 30px;
				padding-bottom: 20px;
			}
			.slider__number {
				font-size: 60px;
				right: 25px;
				padding-left: 50px;
				&:after {
					width: 40px;
				}
			}
		}
		.slick-arrow {
			top: 50%;
			margin-top: -20px;
			&.slick-prev {
				left: 10px;
			}
			&.slick-next {
				right: 10px;
			}
		}
		.slick-dots {
			text-align: center;
			left: 0;
			right: 0;
			top: auto;
			bottom: 0px;
			li {
				@include inline-block;
			}
		}
	}
	@media screen and (max-width: 767px) {
		.slick-slide {
			.slider__title {
				font-size: 20px;
				padding-bottom: 15px;
				&:after {
					width: 35%;
				}
			}
			.slider__number {
				font-size: 40px;
				right: 10px;
				padding-left: 40px;
				&:after {
					width: 30px;
				}
			}
		}
		.slick-arrow {
			width: 30px;
			height: 25px;
			margin-top: -15px;
		}
	}
}
/* main slider */

/* main about */
.about {
	margin-top: 100px;
	h1 {
		text-align: left;
	}
	.about__slider {
		width: 460px;
		position: relative;
		margin: -100px 0 50px 50px;
		&:after {
			content: '';
			background-color: #fff;
			position: absolute;
			top: 50px;
			left: -50px;
			right: 50px;
			bottom: 0;
			z-index: -1;
			box-shadow: 0 0 25px rgba(0,0,0,0.3);
		}
		.slick-slide {
			outline: none;
			position: relative;
			padding-bottom: 50px;
			img {
				width: 100%;
			}
		}
		.about__slider__number {
			font-family: $medium;
			font-size: 18px;
			color: #393939;
			position: absolute;
			right: 140px;
			bottom: 11px;
		}
		.slick-arrow {
			width: 10px;
			height: 17px;
			top: auto;
			bottom: 8px;
			z-index: 1;
			&:before {
				display: none;
			}
			&:after {
				content: '\f104';
				font-family: 'fontAwesome';
				font-size: 26px;
				line-height: 17px;
				color: #393939;
			}
			&.slick-prev {
				left: auto;
				right: 106px;
			}
			&.slick-next {
				right: 70px;
				&:after {
					content: '\f105';
				}
			}
			&:hover {
				opacity: 0.7;
			}
		}
	}
	@media screen and (max-width: 1280px) {
		.about__slider {
			margin: 0;
			&:after {
				left: -25px;
				top: 25px;
			}
		}
	}
	@media screen and (max-width: 991px) {
		margin: 0;
		.about__slider {
			width: 100%;
			margin-top: 15px;
			&:after {
				left: 0;
				right: 0;
			}
			.slick-arrow {
				&.slick-prev {
					right: 50px;
				}
				&.slick-next {
					right: 20px;
				}
			}
			.about__slider__number {
				right: 90px;
			}
		}
	}
}
/* main about */

/* main solution */
.solution {
	position: relative;
	padding: 40px 0 50px;
	&:after {
		content: '';
		width: 50%;
		background-color: #4c6c87;
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		z-index: -1;
	}
	.container {
		padding: 0 15px 0 15px;
	}
	h2 {
		color: #fff;
	}
	.solution__slider {
		margin: 0!important;
		box-shadow: 0 0 25px rgba(0,0,0,0.3);
		.slick-slide {
			position: relative;
			// padding-bottom: 70px;
			font-size: 0;
			a {
				width: 50%;
				position: relative;
				font-size: 16px;
				color: #fff;
				border: 1px solid #fff;
				border-bottom: none;
				box-sizing: border-box;
				&:after {
					content: '';
					position: absolute;
					top: 0;
					left: 0;
					right: 0;
					bottom: 0;
					background-color: rgba(0,0,0,0.2);
				}
				&:nth-last-child(-n+2) {
					border-bottom: 1px solid #fff;
				}
				@include inline-block;
				.solution__slider__block {
					position: absolute;
					left: 50px;
					bottom: 70px;
					right: 50px;
					z-index: 1;
					h3 {
						color: #fff;
						text-transform: uppercase;
					}
					p {
						margin: 0;
					}
				}
				img {
					filter: grayscale(100%);
					transition: 300ms;
				}
				&:hover {
					img {
						filter: grayscale(0);
					}
				}
			}
			.solution__slider__number {
				font-size: 18px;
				color: #fff;
				position: absolute;
				bottom: 0px;
				left: 50%;
				margin-left: -190px;
			}
		}
		.slick-dots {
			width: auto;
			bottom: 2px;
			left: 175px;
			right: auto;
			li {
				button {
					padding: 0;
					width: 15px;
					height: 15px;
					border: 2px solid #fff;
					background-color: transparent;
					border-radius: 100%;
					opacity: 0.7;
					&:before {
						display: none;
					}
					&:hover {
						opacity: 1;
					}
				}
				&.slick-active button {
					opacity: 1;
				}
			}
		}
	}
	@media screen and (max-width: 991px) {
		background-color: #4c6c87;
		padding-bottom: 60px;
		&:after {
			display: none;
		}
		.solution__slider {
			.slick-slide {
				// padding-bottom: 40px;
				.solution__slider__number {
					text-align: center;
					bottom: 0px;
					left: 0;
					right: 0;
					margin: 0;
				}
				a {
					&:after {
						background-color: rgba(0,0,0,0.5);
					}
					.solution__slider__block {
						bottom: 25px;
						left: 25px;
						right: 25px;
						h3 {
							font-size: 18px;
							margin: 0 0 10px;
						}
						p {
							font-size: 14px;
						}
					}
					img {
						filter: grayscale(0);
					}
				}
			}
			.slick-dots {
				bottom: -35px;
				left: 0;
				right: 0;
				li button {
					margin: 0 auto;
				}
			}
		}
	}
	@media screen and (max-width: 767px) {
		.solution__slider {
			.slick-slide a .solution__slider__block {
				h3 {
					font-size: 16px;
					margin-bottom: 5px;
				}
				p {
					font-size: 12px;
				}
			}
		}
	}
	@media screen and (max-width: 520px){
		.solution__slider {
			.slick-slide {
				a {
					display: block;
					width: 100%;
					img {
						display: block;
						width: 100%;
					}
					.solution__slider__block {
						left: 10px;
						right: 10px;
						bottom: 10px;
						h3 {
							font-size: 14px;
						}
						p {
							font-size: 10px;
						}
					}
				}
			}
		}
	}
}
/* main solution */

/* main partners */
.table--slider {
	position: relative;
	&:after {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 50px;
		box-shadow: 0 0 35px rgba(0,0,0,0.4);
	}
	.slick-slide {
		outline: none;
		table {
			width: calc(100% - 100px);
			margin: 50px;
			tr {
				td {
					text-align: center;
					vertical-align: middle;
					a {
						display: block;
						img {
							display: block;
							max-width: 100%;
							margin: 0 auto;
						}
					}
				}
			}
		}
	}
	.slick-arrow {
		width: 10px;
		height: 17px;
		top: auto;
		bottom: 8px;
		z-index: 1;
		&:before {
			display: none;
		}
		&:after {
			content: '\f104';
			font-family: 'fontAwesome';
			font-size: 26px;
			line-height: 17px;
			color: #393939;
		}
		&.slick-next {
			&:after {
				content: '\f105';
			}
		}
		&:hover {
			opacity: 0.7;
		}
	}
	@media screen and (max-width: 767px) {
		&:after {
			bottom: 0;
		}
		.slick-slide table {
			margin: 20px;
		}
	}
	@media screen and (max-width: 480px) {
		.slick-slide {
			table {
				tr td {
					a {
						padding: 10px;
					}
				}
			}
		}
	}
	@media screen and (max-width: 380px) {
		.slick-slide {
			table {
				tr td {
					a {
						padding: 5px;
					}
				}
			}
		}
	}
}
.partners {
	.container {
		padding-bottom: 0px;
	}
	h2 {
		text-align: center;
	}
	.table--slider {
		position: relative;
		&:after {
			content: '';
			position: absolute;
			left: -50px;
			top: 50px;
			right: 50px;
			bottom: 0;
			background-color: #fff;
			z-index: -1;
		}
		.slick-slide {
			background-color: #242424;
			outline: none;
			margin-bottom: 50px;
			table {
				width: calc(100% - 100px);
				margin: 50px;
				tr {
					td {
						width: 33.333%;
						border: 1px solid #fff;
						a {
							&:hover {
								background-color: #fff;
								img {
									&.vis {
										display: none;
									}
									&.hide {
										display: block!important;
									}
								}
							}
						}
					}
				}
			}
			.table--slider__number {
				position: absolute;
				bottom: -37px;
				right: 170px;
			}
		}
		.slick-arrow {
			&.slick-prev {
				left: auto;
				right: 126px;
			}
			&.slick-next {
				right: 90px;
			}
		}
	}
	@media screen and (max-width: 1280px) {
		.table--slider:after {
			left: 0;
			right: 0;
		}
	}
	@media screen and (max-width: 991px) {
		.table--slider {
			.slick-arrow {
				&.slick-prev {
					right: 50px;
				}
				&.slick-next {
					right: 20px;
				}
			}
			.slick-slide {
				.table--slider__number {
					right: 90px;
				}
			}
		}
	}
	@media screen and (max-width: 767px) {
		.table--slider .slick-slide table {
			width: calc(100% - 40px);
			margin: 20px;
		}
	}
}
/* main partners */

/* main customer */
.customer {
	h2 {
		text-align: center;
	}
	.table--slider {
		position: relative;
		&:after {
			content: '';
			position: absolute;
			right: -50px;
			top: 50px;
			left: 50px;
			bottom: 0;
			background-color: #4c6c87;
			z-index: -1;
		}
		.slick-slide {
			background-color: #fff;
			margin-bottom: 50px;
			table tr td {
				border: 1px solid #393939;
				a {
					img {
						transition: 300ms;
					}
					&:hover img {
						filter: grayscale(100%);
					}
				}
			}
			.table--slider__number {
				color: #fff;
				position: absolute;
				bottom: -37px;
				left: 170px;
			}
		}
		.slick-arrow {
			&:after {
				color: #fff;
			}
			&.slick-prev {
				left: 90px;
			}
			&.slick-next {
				left: 126px;
				right: auto;
			}
		}
	}
	@media screen and (max-width: 1280px) {
		.table--slider:after {
			left: 0;
			right: 0;
		}
	}
	@media screen and (max-width: 991px) {
		.table--slider {
			.slick-arrow {
				&.slick-prev {
					left: 20px;
				}
				&.slick-next {
					left: 50px;
				}
			}
			.slick-slide {
				.table--slider__number {
					left: 90px;
				}
			}
		}
	}
	@media screen and (max-width: 767px) {
		.table--slider .slick-slide table { 
			width: calc(100% - 40px);
		}
	}
}
/* main customer */

/* main contacts */
.contacts {
	background-color: #242424;
	color: #fff;
	.container {
		padding: 100px 15px;
	}
	h2 {
		color: #fff;
		text-align: center;
		span {
			color: #05b1e2;
		}
	}
	h4 {
		font-family: $bold;
		color: #fff;
		text-transform: uppercase;
		margin: 0 0 30px 0;
	}
	ul {
		@include ul-default;
		li {
			margin-bottom: 30px;
			a {
				display: block;
				color: #fff;
			}
		}
	}
	.form {
		display: block;
		width: 100%;
		input[type="text"],
		input[type="email"],
		input[type="search"],
		textarea {
			display: block;
			width: 100%;
			border: none;
			border-bottom: 2px solid #fff;
			background-color: transparent;
			font-family: $medium;
			color: #fff;
			padding: 14px 2px;
			margin-bottom: 20px;
			outline: none;
			&::placeholder {
				color: #fff;
			}
			&::-moz-placeholder {
				color: #fff;
			}
		}
		.g-recaptcha {
			@include inline-block;
		}
		.btn {
			float: right;
			margin-top: 20px;
			@include inline-block;
		}
	}
	@media screen and (max-width: 991px) {
		.container {
			padding: 50px 15px;
		}
	}
	@media screen and (max-width: 767px) {
		.container {
			padding: 25px 15px;
		}
		h4 {
			margin-bottom: 10px;
		}
	}
	@media screen and (max-width: 520px) {
		h4 {
			font-size: 18px;
		}
		.form {
			.g-recaptcha {
				display: block;
				transform: scale(0.9);
				transform-origin: 100% 0;
				> div {
					float: right;
				}
			}
		}
	}
}
/* main contacts */

/* main content */