/* footer */
footer {
	background: #ffffff;
	padding: 100px 0;
	text-align: center;
	.container {
		padding: 50px 15px;
		border-top: 1px solid #060606;
		border-bottom: 1px solid #060606;
	}
	.footer--logo {
		display: table;
		margin: 0 auto 40px;
	}
	.artmedia {
		display: table;
		margin: 40px auto 0;
	}
	@media screen and (max-width: 991px) {
		padding: 50px 0px;
	}
	@media screen and (max-width: 767px) {
		padding: 25px 0;
		.container {
			padding: 25px 15px;
		}
		.footer--logo {
			margin-bottom: 25px;
		}
		.artmedia {
			margin-top: 25px;
		}
	}
}
/* footer */