/* page content */
.page {
	.news1, .news {
		.date {
			color: #393939;
		}
		a {
			text-decoration: underline;
			&:hover {
				color: #393939;
				text-decoration: none;
			}
		}
	}
	.page_nav {
		.active {
			color: #393939;
		}
	}
	@media screen and (max-width: 1170px) {
		font-size: 16px;
		#photos-list td img {
			width: 100%;
			height: auto;
		}
	}
	@media screen and (max-width: 480px) {
		p img, p strong img {
			width: 100%!important;
			height: auto!important;
			display: block!important;
			float: none!important;
			margin: 0 0 10px 0!important;		
		}
		#photos-list td {
			width: 50%;
			@include inline-block;
		}
	}
}
/* breadcrumbs */
.breadcrumbs {
	font-size: 16px;
	ul {
		@include ul-default;
		li {
			padding: 5px 0px;
			color: #3361eb;
			@include inline-block;
			a {
				text-decoration: none;
			}
			&:after {
				content: '/';
				margin: 0px 2px 0px 7px;
				color: #282727;
				@include inline-block;
			}
			&:last-of-type:after {
				display: none;
			}
		}
	}
}

/* modal */
.modal {
	.modal-content {
		max-width: 420px;
		border-radius: 0px;
		margin: 0 auto;
		h4{
			margin: 0;
			text-align: center;
		}
		.close {
			width: 25px;
			height: 25px;
			font-family: 'FontAwesome';
			font-size: 25px;
			position: absolute;
			right: 10px;
			top: 14px;
			opacity: 1;
			outline: none;
			&:after {
				content: '\f00d';
			}
			&:hover {
				opacity: 0.7;
			}
		}
		.btn {
			background-color: #ff0000;
			width: 100%!important;
		}
	}
	@media screen and (max-width: 768px) {
		.modal-content h4 {
			font-size: 18px;
		}
	}
}
/* modal */

/* page content */