/* Connection Font Icons */
@font-face {
    font-family: 'FontAwesome';
    src: url('../fonts/fontawesome-webfont.eot');
    src: url('../fonts/fontawesome-webfont.eot') format('embedded-opentype'), 
    url('../fonts/fontawesome-webfont.woff2') format('woff2'), 
    url('../fonts/fontawesome-webfont.woff') format('woff'), 
    url('../fonts/fontawesome-webfont.ttf') format('truetype'), 
    url('../fonts/fontawesome-webfont.svg?v=4.7.0#fontawesomeregular') format('svg');
    font-weight: 400;
    font-style: normal
}
/* Connection Font Icons */

@font-face {
    font-family: 'RobotoBlack';
    src: url('../fonts/RobotoBlack.eot');
    src: url('../fonts/RobotoBlack.eot') format('embedded-opentype'),
         url('../fonts/RobotoBlack.woff2') format('woff2'),
         url('../fonts/RobotoBlack.woff') format('woff'),
         url('../fonts/RobotoBlack.ttf') format('truetype'),
         url('../fonts/RobotoBlack.svg#RobotoBlack') format('svg');
}
@font-face {
    font-family: 'RobotoBold';
    src: url('../fonts/RobotoBold.eot');
    src: url('../fonts/RobotoBold.eot') format('embedded-opentype'),
         url('../fonts/RobotoBold.woff2') format('woff2'),
         url('../fonts/RobotoBold.woff') format('woff'),
         url('../fonts/RobotoBold.ttf') format('truetype'),
         url('../fonts/RobotoBold.svg#RobotoBold') format('svg');
}
@font-face {
    font-family: 'RobotoMedium';
    src: url('../fonts/RobotoMedium.eot');
    src: url('../fonts/RobotoMedium.eot') format('embedded-opentype'),
         url('../fonts/RobotoMedium.woff2') format('woff2'),
         url('../fonts/RobotoMedium.woff') format('woff'),
         url('../fonts/RobotoMedium.ttf') format('truetype'),
         url('../fonts/RobotoMedium.svg#RobotoMedium') format('svg');
}
@font-face {
    font-family: 'RobotoRegular';
    src: url('../fonts/RobotoRegular.eot');
    src: url('../fonts/RobotoRegular.eot') format('embedded-opentype'),
         url('../fonts/RobotoRegular.woff2') format('woff2'),
         url('../fonts/RobotoRegular.woff') format('woff'),
         url('../fonts/RobotoRegular.ttf') format('truetype'),
         url('../fonts/RobotoRegular.svg#RobotoRegular') format('svg');
}
@font-face {
    font-family: 'RobotoLight';
    src: url('../fonts/RobotoLight.eot');
    src: url('../fonts/RobotoLight.eot') format('embedded-opentype'),
         url('../fonts/RobotoLight.woff2') format('woff2'),
         url('../fonts/RobotoLight.woff') format('woff'),
         url('../fonts/RobotoLight.ttf') format('truetype'),
         url('../fonts/RobotoLight.svg#RobotoLight') format('svg');
}
@font-face {
    font-family: 'RobotoItalic';
    src: url('../fonts/RobotoItalic.eot');
    src: url('../fonts/RobotoItalic.eot') format('embedded-opentype'),
         url('../fonts/RobotoItalic.woff2') format('woff2'),
         url('../fonts/RobotoItalic.woff') format('woff'),
         url('../fonts/RobotoItalic.ttf') format('truetype'),
         url('../fonts/RobotoItalic.svg#RobotoItalic') format('svg');
}

$regular: 'RobotoRegular';
$bold: 'RobotoBold';
$light: 'RobotoLight';
$italic: 'RobotoItalic';
$medium: 'RobotoMedium';
$black: 'RobotoBlack';


@mixin inline-block {
	display: inline-block;
	vertical-align: top;
}
@mixin ul-default {
	list-style: none;
	margin: 0;
	padding: 0;
}

*:after, *:before {
	display: block;
	line-height: 1;
}

body {
	display: flex;
	height: 100vh;
	position: relative;
	flex-direction: column;
	font-family: $medium;
	background-color: #e1e1e1;
	color: #393939;
	margin: 0;
	@media screen and (max-width: 1280px) {
		padding-top: 60px;
	}
}

h1 {
	font-family: $bold;
	font-size: 42px;
	text-transform: uppercase;
	text-align: center;
	color: #05b1e2;
	line-height: 1;
	margin-bottom: 35px;
	@media screen and (max-width: 991px) {
		font-size: 32px;
		margin-bottom: 25px;
	}
	@media screen and (max-width: 768px) {
		font-size: 28px;
	}
}
h2 {
	font-family: $bold;
	font-size: 40px;
	color: #393939;
	text-transform: uppercase;
	margin: 0 0px 35px;	
	a {
		font-family: $bold;
		font-size: 40px!important;
		color: #393939;
	}
	@media screen and (max-width: 991px) {
		font-size: 30px;
		margin-bottom: 25px;
		a {
			font-size: 30px!important;
		}
	}
	@media screen and (max-width: 768px) {
		font-size: 24px;
		a {
			font-size: 24px!important;
		}
	}
}
h3 {
	font-family: $bold;
	font-size: 24px;
	color: #393939;
	@media screen and (max-width: 991px) {
		font-size: 20px;
	}
}
h4 {
	font-family: $bold;
	font-size: 22px;
	color: #393939;
	@media screen and (max-width: 991px) {
		font-size: 20px;
	}
}
h5 {
	font-family: $bold;
	font-size: 18px;
	color: #393939;
}
h6 {
	font-family: $bold;
	font-size: 16px;
	text-transform: uppercase;
	color: #393939;
}
a {
	color: #393939;
	transition: 350ms;
	text-decoration: none;
	&:focus {
		color: #393939;
		text-decoration: none;
	}
	&:hover {
		text-decoration: underline;
	}
}
input, textarea, button, select {
	border-radius: 0px;
	-webkit-appearance: none;
}
.main, .page {
	position: relative;
	flex: 1 0 auto;
	font-size: 16px;
	textarea, input {
		background-color: #fff;
		font-family: $regular;
		font-size: 16px;
		padding: 5px 15px;
	}
}
.main {
	section {
		filter: grayscale(100%);
		transition: 400ms;
		&.off {
			filter: grayscale(0);
		}
	}
	.container {
		padding-top: 50px;
		padding-bottom: 50px;
		@media screen and (max-width: 1170px) {
			padding-top: 25px;
			padding-bottom: 25px;
		}
		@media screen and (max-width: 768px) {
			padding-top: 15px;
			padding-bottom: 15px;
		}
	}
	@media screen and (max-width: 767px) {
		section {
			filter: grayscale(0);
		}
	}
}
.btn {
	display: table;
	height: 55px;
	width: auto!important;
	background-color: transparent!important;
	font-family: $medium!important;
	font-size: 16px;
	color: #fff!important;
	text-transform: uppercase;
	cursor: pointer;
	border-radius: 0px;
	border: 3px solid #05b1e2;
	padding: 9px 55px 8px!important;
	transition: 300ms;
	line-height: 1;
	&:focus, &:active {
		background-color: transparent!important;
		color: #fff;
	}
	&:hover, &.active {
		background-color: #05b1e2!important;
		color: #fff!important;
	}	
	@media screen and (max-width: 768px) {
		font-size: 16px;
	}
}

.check--list {
	@include ul-default;
	li {
		display: block;
		position: relative;
		font-size: 16px;
		padding-left: 25px;
		margin-bottom: 5px;
		&:before {
			content: '\f00c';
			position: absolute;
			font-family: 'fontAwesome';
			font-size: 18px;
			color: #000;
			left: 0;
			top: 0px;
		}
	}
}

/* header */
header {
	min-height: 103px;
	position: relative;
	&:before {
		content: '';
		height: 600px;
		background-color: #4c6c87;
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		z-index: -1;
	}
	&:after {
		content: '';
		height: 602px;
		width: 100%;
		background-image: url(../img/bg.png);
		background-repeat: no-repeat;
		background-position: center bottom;
		background-size: cover;
		position: absolute;
		left: 0;
		right: 0;
		top: 600px;
		z-index: -1;
	}
	.container {
		position: relative;
		padding: 30px 15px;
	}
	ul {
		@include ul-default;
	}

	/* header logo */
	.logo {
		display: block;
		width: 178px;
		position: absolute;
		top: 28px;
		left: 0;
		right: 0;
		margin: 0 auto;
		img {
			display: block;
		}
	}
	/* header logo */

	/* header nav */
	.nav {
		margin-top: 9px;
		@include inline-block;
		ul li {
			margin: 0 15px 0 0;
			@include inline-block;
			a {
				font-family: $medium;
				font-size: 16px;
				text-transform: lowercase;
				color: #fff;
				&:hover {
					text-decoration: underline;
				}
			}
			&.active a {
				text-decoration: underline;
			}
		}
	}
	/* header nav */

	/* header langs */
	.langs {
		width: 60px;
		max-height: 35px;
		background-color: #4c6c87;
		border: 1px solid #fff;
		overflow: hidden;
		position: absolute;
		top: 35px;
		right: 50%;
		margin-right: -200px;
		transition: 400ms;
		z-index: 1;
		&:before {
			content: '\f107';
			font-family: 'fontAwesome';
			font-size: 18px;
			color: #fff;
			position: absolute;
			top: 7px;
			right: 10px;
			line-height: 1;
		}
		&:after {
			content: '\f0ac';
			font-size: 20px;
			font-family: 'fontAwesome';
			color: #fff;
			position: absolute;
			top: 6px;
			left: 10px;
			line-height: 1;

		}
		ul {
			margin-top: 35px;
			li {
				a {
					display: block;
					font-family: $bold;
					font-size: 14px;
					text-transform: uppercase;
					padding: 5px 10px;
					color: #fff;
				}
				&.active a {
					text-decoration: underline;
				}
			}
		}
		&.open {
			max-height: 150px;
		}
	}
	/* header langs */

	/* header slogan */
	.slogan {
		font-family: $bold;
		font-size: 18px;
		color: #fff;
		float: right;
		margin-top: 6px;
		@include inline-block;
	}
	/* header slogan */
	@media screen and (max-width: 1280px) {
		min-height: 0px;
		background-color: #4c6c87;
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		z-index: 9;
		&:before, &:after {
			display: none;
		}
		.container {
			padding: 10px 15px 0;
		}
		.logo {
			width: 150px;;
			position: relative;
			top: 0;
			margin: 0 auto 10px;
			img {
				display: block;
				width: 100%;
			}
		}
		.nav {
			display: none;
			width: 100%;
			border-top: 2px solid #fff;
			margin: 0;
			ul li {
				display: block;
				text-align: center;
				margin: 10px 0;
				&:last-child {
					margin-bottom: 0px;
				}
			}
		}
		.nav--btn {
			width: 35px;
			height: 30px;
			position: absolute;
			left: 12px;
			top: 14px;
			padding: 0;
			margin: 0;
			outline: none;
			float: none;
			span {
				position: absolute;
				left: 0;
				right: 0;
				width: 85%;
				height: 3px;
				margin: 0 auto;
				background-color: #fff;
				transition: 350ms;
				&:nth-of-type(1) {
					top: 7px;
				}
				&:nth-of-type(2) {
					top: 14px;
				}
				&:nth-of-type(3) {
					top: 21px;
				}
			}
			&.open {
				span {
					&:nth-of-type(1) {
						top: 14px;
						transform: rotate(45deg);
					}
					&:nth-of-type(2) {
						opacity: 0;
					}
					&:nth-of-type(3) {
						top: 14px;
						transform: rotate(-45deg);
					}
				}
			}
		}
		.slogan {
			position: absolute;
			top: 16px;
			right: 15px;
			margin: 0;
		}
		.langs {
			right: auto;
			left: 60px;
			top: 12px;
			margin: 0;
		}
	}
	@media screen and (max-width: 767px) {
		.slogan {
			display: none;
		}
		.nav--btn {
			left: 8px;
		}
		.langs {
			left: auto;
			right: 10px;
		}
	}
}
/* header */